import React from 'react';
import logo from '../assets/sparksingerlogo.png';

const NavbarFooter: React.FC = () => {
  return (
   <div style={{height:'52vh',display:'flex',flexDirection:'row',alignItems:'end'}}>
     <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{width:'100%'}}>
   <div className='d-flex  align-items-center justify-content-between' style={{
     boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)', 
     width: '100%', 
     height: '10vh',
     color:'#9ecafc'
   }}>
           <div className=" container-sm d-flex justify-content-between align-items-center h-25 w-100" style={{color:"whe##bbd8da",display:'flex',justifyContent:"space-between",width:'200px'}}>
             <div className="p-2" style={{padding: '10px',color:'#9ecafc',fontWeight:500 }}>
                SparkSinger
             </div>
             <div className="p-2 " style={{ 
     padding: '10px', 
     fontFamily: 'Poppins, sans-serif', 
     fontSize: '14px', 
     fontWeight: '400',
     color:'#9ecafc' 
   }}>
               <select>
                 <option>English US</option>
               </select>
               </div>
    
           </div>
         </div>
       </nav></div>
  );
};

export default NavbarFooter;
