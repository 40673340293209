import React from 'react';
import logo from '../assets/sparksingerlogo.png';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
<div className='d-flex  align-items-center justify-content-between' style={{
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
  width: '100%', 
  height: '10vh'
}}>
        <div className=" container-sm d-flex justify-content-between align-items-center h-25 w-100" style={{color:"wheat",display:'flex',justifyContent:"space-between",width:'200px'}}>
          <div className="p-2" style={{padding: '10px' }}>
            <img src={logo} width={200} className="img-fluid" alt="Logo"  />
          </div>
          <div className="p-2 " style={{ 
  padding: '10px', 
  fontFamily: 'Poppins, sans-serif', 
  fontSize: '14px', 
  fontWeight: '400',
  color:'blue',
  cursor:'pointer' 
}}>
            Submit your feedback
            </div>
 
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
