import React, { useEffect, useState } from 'react';
import './styles/NavBar.css';
import myUrl from '../config/URLs';
import axios from 'axios';

type Feedback = {
  _id: string | number;
  Name: string;
};

type FeedbackControl = {
  __id: string;
  ControlType: string;
  ControlInputType: string;
  ControlName: string;
  FeedBackID: string;
  SelectControlValues: string[];
  Description: string;
  createdAt: string;
  updatedAt: string;
};

type Playlist = {
  _id: string | number;
  Name: string;
};

type ControlValues = {
  [key: string]: string | FileList | undefined;
};

const NavBarContent: React.FC = () => {
  const [Feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [feedbackControls, setFeedbackControls] = useState<FeedbackControl[]>([]);
  const [feedbackID, setFeedbackID] = useState<string>('');
  const [controlValues, setControlValues] = useState<ControlValues>({});
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state for submit button

  async function getFeedbacks(): Promise<Feedback[]> {
    try {
      const url = `${myUrl.backend}/FeedBack/GetFeedBackItem`;
      const response = await axios.get<{ data: Feedback[] }>(url);
      return response.data.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async function getFeedbackControl(id: string): Promise<FeedbackControl | null> {
    try {
      setControlValues(prevState => ({
        ...prevState,
        _id: id,
      }))
      
      const url = `${myUrl.backend}/FeedBack/GetFeedBackControlByFeedBackID?FeedBackID=${id}`;
      const response = await axios.get<{ data: FeedbackControl }>(url);
      return response.data.data;
    } catch (err) {
      console.log("Error fetching feedback by ID:", err);
      return null;
    }
  }

  const FeedBackOnchange = async (id: string) => {
    try {
      const feedbackControl = await getFeedbackControl(id);
      console.log(feedbackControl,'feedbackControl');
      
      if (feedbackControl !== null) {
        setFeedbackControls([feedbackControl]); // Only set if feedbackControl is not null
      } else {
        setFeedbackControls([]); // Clear feedback controls if none found
      }
    } catch (error) {
      console.error("Failed to fetch feedback control:", error);
    }
  };

  useEffect(() => {
    (async () => {
      const Feedbacks = await getFeedbacks();
      setFeedbacks(Feedbacks);
    })();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ControlName: string
  ): void => {
    if (ControlName === 'Attachments') {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        setControlValues(prevState => ({
          ...prevState,
          [ControlName]: files,
        }));
      }
    } else {
      const { value } = e.target;
      setControlValues(prevState => ({
        ...prevState,
        [ControlName]: value,
      }));
    }
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const id = e.target.value;
    if (id) {
      FeedBackOnchange(id);
    }
  };

  const HandleSubmit = async () => {
    setIsLoading(true); 
     try {
      const url = `${myUrl.backend}/FeedBack/CreateUserFeedBack`;
      const response = await axios.post<{ data: FeedbackControl }>(url, controlValues);
      console.log('Feedback submitted:', response.data);
 
    } catch (err) {
      console.log("Error submitting feedback:", err);
    } finally {
      // Add a 3-second delay before stopping the loading spinner
      setTimeout(() => {
        window.location.reload(); // Reload the entire page

        setIsLoading(false); // Stop loading after 3 seconds
      }, 3000);
    }
  }
  
 console.log(Feedbacks,'Feedbacks');
 
  return (
    <div className="container-sm ps-4">
      <div className="d-flex justify-content-between align-items-center">
        <div style={{
          display: 'flex', gap: '10px', fontSize: '14px', fontFamily: 'Arial, sans-serif', color: '#4a90e2', alignItems: 'center', padding: '5px 0',
        }}>
          <div style={{ fontWeight: 'bold' }}>SparkSinger</div>
          <div style={{ color: '#aaa' }}>{'>'}</div>
          <div style={{ fontStyle: 'italic', cursor: 'pointer' }}>Submit your feedback</div>
        </div>

        <div className="input-group" style={{ maxWidth: 300 }}>
          <span
            className="input-group-text"
            id="search-icon"
            style={{ borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px' }}
          >
            <i className="bi bi-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-icon"
            style={{
              borderBottomRightRadius: '20px', borderTopRightRadius: '20px', outline: 'none !important', border: '1px solid #DEE2E6',
            }}
          />
        </div>
      </div>

      <div style={{ marginTop: 40 }}>
        <div style={{ color: '#7439ec', cursor: 'pointer' }}>
          <h2>Submit your feedback</h2>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <div style={{
          color: '#7439ec', fontSize: '1rem', width: '50%', fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
        }}>
          Please let us know if there's a problem or feedback you'd like to report about your SparkSinger
          experience. Choose the category that best fits your issue.
        </div>
      </div>
      <div>
        <div className="dropdown-container">
          <select className="custom-select" onChange={handleDropdownChange}>
            <option value="">-</option>
            {Feedbacks.length > 0 &&
              Feedbacks.map((data: Feedback, index: number) => (
                <option key={index} value={data._id}>
                  {data.Name}
                </option>
              ))}
          </select>
          <span className="dropdown-icon">&#9660;</span>
        </div>
      </div>

      <div className="feedback_control_body">
        {Array.isArray(feedbackControls) && feedbackControls.length > 0 ? (
          feedbackControls.flat().map((data, index) => {
            return (
              <div key={index} className="feedback-control-item">
                <label htmlFor={data.ControlName}>{data.ControlName}</label>
                {data.ControlType === 'textbox' && (
                  <input
                    type={data.ControlInputType || 'text'}
                    id={data.ControlName}
                    name={data.ControlName}
                    value={controlValues[data.ControlName] as string}
                    onChange={(e) => handleChange(e, data.ControlName)}
                  />
                )}
                {data?.ControlType === 'textarea' && (
                  <textarea
                    id={data.ControlName}
                    name={data.ControlName}
                    value={controlValues[data.ControlName] as string}
                    onChange={(e) => handleChange(e, data.ControlName)}
                  />
                )}
                {data?.ControlType === 'dropdown' && (
                  <select
                    id={data.ControlName}
                    name={data.ControlName}
                    value={controlValues[data.ControlName] as string}
                    onChange={(e) => handleChange(e, data?.ControlName)}
                  >
                    <option>{'-'}</option>
                    {data?.SelectControlValues?.length > 0 ? (
                      data.SelectControlValues.map((option, idx) => (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      ))
                    ) : (
                      <option value="">No options available</option>
                    )}
                  </select>
                )}
                {data.ControlType === 'file' && (
                  <input
                    type="file"
                    id={data.ControlName}
                    name={data.ControlName}
                    onChange={(e) => handleChange(e, data.ControlName)}
                  />
                )}
                {data.Description && <p className="control-description">{data.Description}</p>}
              </div>
            );
          })
        ) : (
          <p>No feedback controls available</p>
        )}
      </div>

      <div className="SubmitButton" style={feedbackControls.length > 0 ? { display: 'block' } : { display: 'none' }}>
        <button className="submit-btn" onClick={HandleSubmit} disabled={isLoading}>
          {isLoading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </div>
  );
};

export default NavBarContent;
