
import './App.css';
import Navbar from './components/Navbar';
import NavBarContent from './components/NavBarContent';
import NavbarFooter from './components/NavbarFooter';

function App() {
  return (
     
     <>
     <Navbar />
     <NavBarContent />
     <NavbarFooter />
     </>
   
  );
}

export default App;
